import React from 'react';
import logo from './logo.svg';
import './App.css';

import { ArrowRightIcon } from '@heroicons/react/24/solid'
import AnyListLoginForm from './AnyListLoginForm';

function App() {
  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-lg">
        <div className="overflow-hidden bg-white shadow sm:rounded-lg  divide-y divide-slate-200">
          <div className="px-4 py-5 sm:px-6 ">
            <div className="flex items-center gap-x-2">
              <div>
                <img src="anylist.png" className="bg-sky-500" />
              </div>
              <div>
                <ArrowRightIcon className="h-6 w-6"/>
              </div>
              <div>
                <img src="kroger.png" />
              </div>
            </div>
            <p className="text-center text-sm text-gray-600 mt-4">
              Send a list from AnyList to a Kroger branded shopping cart.
            </p>
          </div>
          <div className="p-4">
            <p className="text-center">
              Start by entering your AnyList email &amp; password
            </p>
            <AnyListLoginForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
